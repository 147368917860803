import { DateTime } from "luxon";
import { AxiosResponse } from "axios";

interface GetSerializer {
  type: string;
  token: string;
  expires_at: string;
}

export default class AccessTokenModel {
  constructor(
    public type: string,
    public token: string,
    public expiresAt: DateTime
  ) {}

  public static getSerializer(response: unknown): AccessTokenModel {
    const { data } = response as AxiosResponse<GetSerializer>;

    return new AccessTokenModel(
      data.type,
      data.token,
      DateTime.fromFormat(data.expires_at, "yyyy-MM-dd'T'HH:mm:ss.u'+00:00'", {
        zone: "utc"
      })
    );
  }
}
