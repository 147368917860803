import { RouteConfig } from "vue-router";

const routes: RouteConfig = {
  path: "/",
  component: () => import("@/templates/App/index.vue"),
  children: [
    {
      path: "/",
      name: "users",
      component: () => import("@/views/App/Users/index.vue")
    },
    {
      path: "view-user-app/:username",
      name: "view-user-app",
      component: () => import("@/views/App/ViewUserApp/index.vue")
    }
  ].map((route: RouteConfig) => {
    route.meta = {
      authRequired: true
    };

    return route;
  })
};

export default routes;
