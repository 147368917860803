import AccessTokenModel from "@/models/entities/AccessToken";
import { DateTime } from "luxon";
import { LOCAL_STORAGE_SYSTEM_PREFIX } from "./constants";

const LOCAL_STORAGE_TOKEN_NAME = `${LOCAL_STORAGE_SYSTEM_PREFIX}/accessToken`;

export function keepAccessToken(accessToken: AccessTokenModel): void {
  const { type, token, expiresAt } = accessToken;

  localStorage.setItem(
    LOCAL_STORAGE_TOKEN_NAME,
    JSON.stringify({ type, token, expiresAt: expiresAt.toString() })
  );
}

export function getAccessToken(): AccessTokenModel | undefined {
  const accessToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_NAME);

  if (accessToken) {
    const { type, token, expiresAt } = JSON.parse(accessToken);

    return new AccessTokenModel(
      type,
      token,
      DateTime.fromJSDate(new Date(expiresAt))
    );
  } else {
    return undefined;
  }
}

export function removeToken(): void {
  localStorage.clear();
}
