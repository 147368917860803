import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

// Routes
import appRoutes from "./app";
import accountRoutes from "./account";
import { getAccessToken } from "@/utils/auth";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [appRoutes, accountRoutes];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  const token = getAccessToken();

  if (to.meta?.authRequired) {
    if (token) {
      return next();
    } else {
      return next({ name: "sign-in" });
    }
  } else {
    return next();
  }
});

export default router;
