import { RouteConfig } from "vue-router";

const routes: RouteConfig = {
  path: "/account",
  component: () => import("@/templates/Account/index.vue"),
  children: [
    {
      path: "sign-in",
      name: "sign-in",
      component: () => import("@/views/Account/SignIn/index.vue")
    }
  ]
};

export default routes;
